// Dependencies
import React from "react"
import { Link } from "gatsby"
// Components
import ProjectLink from "../components/ProjectLink"
import JournalLink from "../components/JournalLink"
import { Arrow } from "../components/Icons"

export const RelatedProjects = ({ className = "innerx2", projects }) => {

	return (
		<div className={`related-projects ${className}`}>
			<div className="related-title innerx2 guttersx4">
				<h3>Related Projects</h3>
				<h3><Link to="/projects" className="h3 has-arrow">View all <Arrow className="icon arrow" /></Link></h3>
			</div>
			<div className="grid-3 innerx2 guttersx4">
				{projects && projects.map((entry, index) => (
					<ProjectLink {...entry} key={`${entry.id}${index}`} />
				))}
			</div>
		</div>
	)
}

export const RelatedJournals = ({ className = "innerx2", journals }) => {

	return (
		<div className={`related-journals ${className}`}>
			<div className="related-title">
				<h3>Related Journal</h3>
				<h3><Link to="/journal" className="h3 has-arrow">View all <Arrow className="icon arrow" /></Link></h3>
			</div>
			<div className="grid-3 innerx2 guttersx4">
				{journals && journals.map((entry, index) => (
					<JournalLink {...entry} key={`${entry.id}${index}`} />
				))}
			</div>
		</div>
	)
}

export default {}