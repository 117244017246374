// Dependencies
import React, { useEffect } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
// Hooks
import { updateColor } from "../hooks/useUpdateHeader"
// Components
import Layout from "../components/Layout"
import Image from "../components/Image"
import RichText from "../components/RichText"
import Quote from "../components/Quote"
import { RelatedProjects, RelatedJournals } from "../components/Related"
import SocialLink from "../components/SocialLink"

const PersonTemplate = ({ data }) => {

	// Content
	const { 
		name, 
		title, 
		bio, 
		testimonial, 
		portrait, 
		thumbnail,
		contactDetails, 
		linkedIn, 
		instagram, 
		twitter, 
		project, 
		journal 
	} = data.contentfulPerson

	useEffect(() => {
		updateColor('var(--black)')
	}, [])

	const image = portrait || thumbnail

  return (
    <Layout 
			headerTitle="Back to people" 
			headerLink="/people"
			hideLogo 
			theme={{
				background: 'var(--red-bg)',
				highlight: 'var(--red)'
			}}
			title={`${name} | AFK`}
		>
			<div className="person-view header-offset">
				<h1 className="guttersx4 outerx4">{`${name}, ${title}`}</h1>
				
				<div className="person-view-grid guttersx4 outerx2">
					<div className="person-portrait mbx4">
						{image && <Image image={image?.cover} alt={name} />}
						<div className="outerx2 p4 has-links">
							<RichText content={contactDetails} />
							<div className="person-socials outer">
								{linkedIn && <SocialLink href={linkedIn} title={`${name} on LinkedIn`} linkedIn />}
								{instagram && <SocialLink href={instagram} title={`${name} on Instagram`} instagram />}
								{twitter && <SocialLink href={twitter} title={`${name} on Twitter`} twitter />}
							</div>
						</div>
					</div>
					<div className="person-copy p4">
						<div className="person-bio mbx4 has-indents">
							<RichText content={bio} />
						</div>
						<div className="person-quote">
							{testimonial && <Quote className="" {...testimonial} plain />}
						</div>
					</div>
				</div>

				{/* Related projects */}
				{project && (
					<RelatedProjects projects={project} />
				)}

				{/* Related Journals */}
				{journal && (
					<RelatedJournals journals={journal} />
				)}
			</div>
    </Layout>
  )
}

PersonTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default PersonTemplate

export const PersonTemplatePageQuery = graphql`
  query PersonTemplateQuery($slug: String!) {
    contentfulPerson(slug: {eq: $slug}) {
			name
			title
			bio {
				raw
			}
			testimonial {
				copy {
					copy
				}
				citation
			}
			thumbnail {
				file {
					contentType
					url
				}
				cover: gatsbyImageData(
					layout: FULL_WIDTH
					formats: [JPG]
				)
				crop: gatsbyImageData(
					layout: FULL_WIDTH
					formats: [JPG]
				)
			}
			portrait {
				file {
					contentType
					url
				}
				cover: gatsbyImageData(
					layout: FULL_WIDTH
					formats: [JPG]
				)
				crop: gatsbyImageData(
					layout: FULL_WIDTH
					formats: [JPG]
				)
			}
			linkedIn
			instagram
			twitter
			contactDetails {
				raw
				# Turned off linking assets as they were not using this feature
				#references {
				#	... on ContentfulAsset {
				#		__typename
				#		contentful_id
				#		file {
				#			url
				#		}
				#	}
				#}
			}
			## Related content
			project {
				...ProjectThumbnailFragment
			}
			journal {
				...JournalLinkFragment
			}
    }
  }
`